<template>
  <div class="table-container table-height table-responsive custom-scrollbar" ref="tableContainer">
    <table class="table table-borderless align-middle" id="table-list" ref="tablelist">
      <thead class="">
        <tr class="fs-13 fw-500 font-secondary m-0 text-nowrap">
          <th scope="col" v-for="(item, index) of tableHeading" :key="index">{{
            item.name
          }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="skeletonLoad">
          <tr class="align-middle" v-for="i in tableHeading.length" :key="i">
            <td class="text-uppercase" v-for="j in tableHeading.length" :key="j">
              <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd" wave-color="#fff"
                :rounded="true" />
            </td>
          </tr>
        </template>
        <template v-if="TransactionList.length >= 1 && !skeletonLoad">
          <tr class="border-0" v-for="(translist, index) in TransactionList" :key="index">
            <td>
              <div class="d-flex gap-2 align-items-center">
                <!-- <img src="https://tradebit.io/backend/public/currency_images/tether.png" width="23"> -->
                <h6 class="fs-15 m-0 fw-500">{{ translist.symbol }}</h6>
              </div>
            </td>
            <td>
              <h6 class="subtitle">{{ translist.chain_type }}</h6>
            </td>
            <td>
              <h6 class="subtitle">{{ translist.amount }}</h6>
            </td>
            <td>
              <h6 class="subtitle">{{ translist.user_wallet_address }}</h6>
            </td>
            <td>
              <span class=" subtitle text-capitalize">{{ translist.status }}</span>
            </td>
            <td>
              <h6 class="subtitle">
                {{
                  new Date(translist.created_at).toLocaleDateString(
                    "en-US",
                    options
                  )
                }}
              </h6>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="col-12 text-center" v-if="loading && !skeletonLoad">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="TransactionList.length < 1 && !skeletonLoad"
      class="d-flex border-0 flex-column text-center justify-content-center py-4">
      <div>
        <img width="70" :src="require(`../../assets/images/icons/no-record.svg`)" />
      </div>
      <span class="fw-500 fs-14">No record found</span>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
import ApiClass from "@/api/api";
export default {
  name: "DepositHistory",
  components: {
    // Heading,
    VueSkeletonLoader
  },
  data() {
    return {
      search: "",
      firstload: true,
      tableHeading: [
        { name: 'Coin' },
        { name: 'Network' },
        { name: 'Amount' },
        { name: 'Wallet Address' },
        { name: 'Status' },
        { name: 'Date and Time' }
      ],

      TransactionList: [],
      updateRes: [],
      options: {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      },
      loading: false,
      skeletonLoad: true,
    };
  },
  methods: {
    searchData() {
      var search = this.search.toUpperCase();
      if (search) {
        this.TransactionList = this.updateRes.filter((e) => e.symbol.includes(search) || e.user_wallet_address.toLowerCase() === search.toLowerCase());
      } else {
        this.TransactionList = this.updateRes.filter((e) => e);
        // this.searchZeroData();
      }
    },
    // handleScroll() {
    //   let el = this.$refs.tableContainer;
    //   let table = this.$refs.tablelist;

    //   if (this.currPage <= this.data.current_page && this.data.current_page < this.data.last_page) {
    //     if (el.scrollTop + el.clientHeight >= table.clientHeight) {
    //       this.currPage = this.data.current_page + 1;
    //       this.$emit('updateResponse', this.currPage);
    //     }
    //   }
    // },
    async callApi() {
      this.firstload ? this.skeletonLoad = true : this.loading = true;
      var user_data = JSON.parse(localStorage.getItem("user"));
      var response = await ApiClass.getRequest(
        "deposit/get/" + user_data.id,
        true
      );

      if (response.data.status_code == 1) {
        // console.log(response);
        this.TransactionList = this.updateRes = response.data.data;
      }
      this.loading = false;
      this.skeletonLoad = false;
    }
  },
  async mounted() {
    await this.callApi();
  },
};
</script>

<style scoped>
.success-badge {
  color: #14B8A6;
  background-color: #CCFBF1;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
}

.danger-badge {
  color: #EF4444;
  background-color: #FEE2E2;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
}

.table>:not(:first-child) {
  border-top: 1px solid var(--bg);
}

#table-list tbody td .subtitle {
  color: var(--text);
}

.table-height {
  max-height: 20rem;
}
</style>